import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import gif from "../images/best.gif"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="notFound-wrapper">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <img src={gif} alt="my computer gif" />
    </div>

  </Layout>
)

export default NotFoundPage
